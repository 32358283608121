code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #022c40;
  font-weight: bold;
  font-size: 40px;
}
